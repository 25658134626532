var searchKeys = [{
  label: '话题ID：',
  key: "ids",
  placeholder: "话题ID，多个一行一个",
  textarea: true,
  hidden: true
}, {
  label: '话题关键词：',
  key: "name",
  placeholder: "话题关键词查询",
  input: true
}, {
  label: '类型：',
  key: "type",
  placeholder: "类型",
  select: true,
  allowClear: true,
  initialValue: ""
}, {
  label: '状态：',
  key: "status",
  placeholder: "状态",
  select: true,
  allowClear: true,
  initialValue: 0
}, {
  label: '是否推荐：',
  key: "isrecommend",
  placeholder: "是否推荐",
  select: true,
  allowClear: true,
  initialValue: ""
}, {
  label: '配置时间：',
  key: "updatetime",
  placeholder: ["配置时间_开始", "配置时间_结束"],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss"
  }
}, {
  key: "button",
  button: true
}];
var searchKeys2 = [{
  label: '类型：',
  key: "type",
  placeholder: "类型",
  select: true,
  initialValue: ""
}, {
  label: '作品ID：',
  key: "ids",
  placeholder: "作品ID，多个一行一个",
  textarea: true,
  hidden: true
}, {
  label: '作品关键词：',
  key: "title",
  placeholder: "作品关键词查询",
  input: true
}, {
  label: '话题ID：',
  key: "topicids",
  placeholder: "话题ID，多个一行一个",
  textarea: true,
  hidden: true
}, {
  label: '话题关键词：',
  key: "name",
  placeholder: "话题关键词查询",
  input: true
}, {
  label: '关联时间：',
  key: "updatetime",
  placeholder: ["关联时间_开始", "关联时间_结束"],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss"
  }
}, {
  key: "button",
  button: true
}];
var tableColumns = [{
  title: "话题ID",
  dataIndex: "id",
  key: "id",
  width: 180
}, {
  title: "更新时间",
  dataIndex: "updatetime",
  key: "updatetime",
  width: 180
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "话题名称",
  dataIndex: "name",
  key: "name",
  width: 240,
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "类型",
  dataIndex: "type",
  key: "type",
  width: 80,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "是否推荐",
  dataIndex: "isrecommend",
  key: "isrecommend",
  width: 120,
  scopedSlots: {
    customRender: "isrecommend"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: 100,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 250,
  scopedSlots: {
    customRender: "action"
  }
}];
var tableColumns2 = [{
  title: "作品ID",
  dataIndex: "videoid",
  key: "videoid",
  width: 180,
  scopedSlots: {
    customRender: "videoid"
  }
}, {
  title: "作品类型",
  dataIndex: "type",
  key: "type",
  width: 120,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "关联时间",
  dataIndex: "createtime",
  key: "createtime",
  width: 180
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "关联话题",
  dataIndex: "topics",
  key: "topics",
  scopedSlots: {
    customRender: "topics"
  }
  // width: 180,
  // sorter: true,
  // sortOrder: "descend",
}
//   {
//     title: "操作",
//     key: "action",
//     dataIndex: "action",
//     fixed: "right",
//     width: 250,
//     scopedSlots: {
//       customRender: "action",
//     },
//   },
];

var options = {
  type: [{
    id: "",
    name: "全部"
  }, {
    id: 1,
    name: "普通"
  }, {
    id: 2,
    name: "活动"
  }, {
    id: 3,
    name: "广告"
  }],
  isrecommend: [{
    id: "",
    name: "全部"
  }, {
    id: 0,
    name: "否"
  }, {
    id: 1,
    name: "是"
  }],
  status: [{
    id: "",
    name: "全部"
  }, {
    id: 0,
    name: "生效"
  }, {
    id: 1,
    name: "失效"
  }]
};
export { searchKeys, searchKeys2, tableColumns, tableColumns2, options };