import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-bottom": "10px"
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["topic:btn:add"],
      expression: "['topic:btn:add']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.showModal(1);
      }
    }
  }, [_vm._v("添加")])], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "id",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(name) {
        return [_c("span", [_vm._v(" " + _vm._s("#".concat(name)) + " ")])];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [_c("span", {
          style: {
            color: status == 1 ? "#f57575" : "#64c136"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatField("status", status)) + " ")])];
      }
    }, {
      key: "isrecommend",
      fn: function fn(isrecommend, _ref) {
        var sort = _ref.sort;
        return [_c("span", [_vm._v(" " + _vm._s("".concat(_vm.formatField("isrecommend", isrecommend)).concat(sort !== undefined ? "，排序" + sort : "")) + " ")])];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [_c("span", [_vm._v(" " + _vm._s(_vm.formatField("type", type)) + " ")])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("div", {
          staticClass: "btn-action-wrap"
        }, [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["topic:btn:edit"],
            expression: "['topic:btn:edit']"
          }],
          attrs: {
            ghost: "",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.showModal(2, item);
            }
          }
        }, [_vm._v("编辑")]), _c("a-popconfirm", {
          attrs: {
            placement: "topLeft",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.confirm(item.id, item.status == 0 ? 1 : 0);
            }
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("p", [_vm._v("温馨提示")]), _c("p", [_vm._v(" " + _vm._s(item.status == 1 ? "是否确认恢复？" : "是否确认删除？") + " ")])]), item.status == 1 ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["topic:btn:recover"],
            expression: "['topic:btn:recover']"
          }],
          staticStyle: {
            color: "#67c23a",
            "border-color": "#67c23a"
          },
          attrs: {
            ghost: ""
          }
        }, [_vm._v("恢复")]) : _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["topic:btn:del"],
            expression: "['topic:btn:del']"
          }],
          attrs: {
            ghost: "",
            type: "danger"
          }
        }, [_vm._v("删除")])], 2), _c("a-button", {
          on: {
            click: function click($event) {
              return _vm.previewLog(item.id);
            }
          }
        }, [_vm._v("日志")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.modaltile,
      width: 700,
      footer: null,
      centered: ""
    },
    on: {
      cancel: function cancel($event) {
        return _vm.resetForm("ruleForm");
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", _vm._b({
    ref: "ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules
    }
  }, "a-form-model", _vm.layout, false), [_c("a-form-model-item", {
    attrs: {
      label: "话题名称",
      prop: "name"
    }
  }, [_c("div", {
    staticClass: "input-num-tip",
    attrs: {
      "data-num": "".concat(_vm.getStrLen(_vm.ruleForm.name), "/150")
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      "auto-size": "",
      maxLength: 150,
      placeholder: "输入话题"
    },
    model: {
      value: _vm.ruleForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "name", $$v);
      },
      expression: "ruleForm.name"
    }
  })], 1)]), _c("a-form-model-item", {
    attrs: {
      label: "话题类型",
      prop: "type"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.ruleForm.type,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "type", $$v);
      },
      expression: "ruleForm.type"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 普通 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 活动 ")]), _c("a-radio", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 广告 ")])], 1)], 1), [2, 3].includes(_vm.ruleForm.type) ? [_c("a-form-model-item", {
    staticClass: "uploader-item",
    attrs: {
      label: "话题封面",
      prop: "coverurl"
    }
  }, [_c("a-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      "list-type": "picture-card",
      accept: ".jpg,.png",
      "show-upload-list": false,
      "before-upload": _vm.beforeUpload,
      customRequest: _vm.handleUpload
    }
  }, [_vm.ruleForm.coverurl ? _c("img", {
    staticClass: "coverurl",
    attrs: {
      src: _vm.ruleForm.coverurl
    }
  }) : _c("div", [_c("a-icon", {
    attrs: {
      type: _vm.uploading ? "loading" : "cloud-upload"
    }
  })], 1)]), _c("span", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "line-height": "1.6"
    }
  }, [_vm._v("只支持：jpg、png，"), _c("br"), _vm._v("大小不超过5M，"), _c("br"), _vm._v("建议尺寸1125*1125的图片。")])], 1), _c("a-form-model-item", {
    attrs: {
      label: "话题描述",
      prop: "topicdescription"
    }
  }, [_c("div", {
    staticClass: "input-num-tip",
    attrs: {
      "data-num": "".concat(_vm.getStrLen(_vm.ruleForm.topicdescription), "/500")
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      maxLength: 500,
      "auto-size": "",
      placeholder: "输入话题描述"
    },
    model: {
      value: _vm.ruleForm.topicdescription,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "topicdescription", $$v);
      },
      expression: "ruleForm.topicdescription"
    }
  })], 1)])] : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: "设为推荐",
      prop: "isrecommend"
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.ruleForm.isrecommend,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "isrecommend", $$v);
      },
      expression: "ruleForm.isrecommend"
    }
  }, [_vm._v(" 推荐 ")])], 1), _vm.ruleForm.isrecommend ? [_c("a-form-model-item", {
    attrs: {
      label: "推荐排序",
      prop: "sort"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "262px"
    },
    attrs: {
      precision: 0,
      min: 0,
      placeholder: "数值越大越靠前"
    },
    model: {
      value: _vm.ruleForm.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "sort", $$v);
      },
      expression: "ruleForm.sort"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s("\u5F53\u524D\u6700\u5927\u6570\u503C\uFF1A".concat(_vm.maxrecommend)))])], 1)] : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["topic:btn:save"],
      expression: "['topic:btn:save']"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v(" 提交 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.resetForm("ruleForm");
      }
    }
  }, [_vm._v(" 取消 ")])], 1)], 2)], 1), _c("a-modal", {
    attrs: {
      title: "日志",
      width: 800,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-table", {
    attrs: {
      dataSource: _vm.dataSource2,
      columns: _vm.tableColumns2,
      rowKey: function rowKey(record, index) {
        return index;
      },
      loading: _vm.loading2,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange2
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };